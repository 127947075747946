<template>
  <div v-if="offer_data['store_url']" class="mt-4">
    <a :href="offer_data['store_url']" target="_blank">Перейти на карточку</a>
  </div>
  <div v-if="offer_data !== undefined">
    <WbOffer
        v-if="marketplace === 'wb'"
        @new-rule="newRule()"
        :offer="offer_data"
        :error_data="error_data"
        :product="product"
        :product-attribute="productAttribute"
        :batch-action="batchAction"
        @offer-change="offerChange"
    />
    <OzonOffer
        v-if="marketplace === 'ozon'"
        @new-rule="newRule()"
        :offer="offer_data"
        :error_data="error_data"
        :product="product"
        :product-attribute="productAttribute"
        :batch-action="batchAction"
        @offer-change="offerChange"
    />
  </div>
  <div class="buttons mt-5 mb-12">
    <ButtonComponent ref="saveOfferButton" class="btn btn-primary" @click="saveOffer">Сохранить</ButtonComponent>
    <ButtonComponent ref="uploadOfferButton" class="btn btn-primary" @click="uploadOffer" style="margin-left: 5px">
      {{this.upload_button}}
    </ButtonComponent>
  </div>
</template>

<script>

import WbOffer from "@/components/match/WbOffer.vue";
import ButtonComponent from "@/components/tools/Button.vue";
import {create, update, upload} from "@/api/offer";
import {user} from "@/data/user";
import OzonOffer from "@/components/match/OzonOffer.vue";

export default {
  name: "BaseOffer",
  components: {OzonOffer, ButtonComponent, WbOffer},
  data() {
    return {
      save_loading: false,
      offer_data: {},
      error_data: {},
      upload_button: 'Выгрузить'
    }
  },
  emits: ['new-rule'],
  props: {
    offer: {
      type: Object
    },
    product: {
      type: Object
    },
    marketplace: {
      type: String
    },
    productAttribute: {
      type: String
    },
    batchAction: {
      type: String
    }
  },
  methods: {
    async saveOffer() {
      const company_id = await user.getActiveCompany()
      this.$refs.saveOfferButton.startLoading()
      let res = null
      if (this.offer_data.id) {
        res = await update(company_id, this.offer_data)
      } else {
        res = await create(company_id, this.offer_data)
      }
      this.offer_data = res.data

      if (!this.offer_data['marketplace_data']) {
        this.$emit('new-rule')
      }
      setTimeout(() => this.$refs.saveOfferButton.endLoading(), 1000)
    },
    async uploadOffer() {
      if (Object.keys(this.error_data).length === 0) {
        this.$refs.uploadOfferButton.startLoading()
        await this.saveOffer()
        const company_id = await user.getActiveCompany()
        await upload(company_id, this.offer_data.store_id, [this.offer_data.id])
        this.$refs.uploadOfferButton.endLoading()
        this.upload_button = 'Выгружен'
      } else {
        Object.values(this.error_data).forEach((value) => {
          alert(`Товар ${this.product.name} не может быть выгружен из за ошибок: ${value}`);
        })
      }
    },
    newRule() {
      this.$emit('new-rule')
    },
    offerChange(new_offer) {
      this.offer_data = new_offer
    }
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer) {
        this.offer_data = {...newOffer};
      }
    },
  },
}
</script>