import {createWebHistory, createRouter} from 'vue-router'

import LoginView from './views/Login.vue'
import HomeView from './views/Home.vue'
import ProductsView from './views/Products.vue'
import StoresView from './views/Stores.vue'
import SettingsView from './views/Settings.vue'
import SuppliersView from './views/Suppliers.vue'
import RulesView from './views/Rules.vue'
import MatchView from './views/Match.vue'
import WbCardsView from './views/WbCards.vue'
import TemplateView from './views/InfographicsTemplates.vue'
import {menu} from '@/data/menu'
import {auth} from '@/data/auth'
import {PageInfo} from "@/data/page";

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Главная',
        },
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Вход',
        },
        component: LoginView
    },
    {
        path: '/stores',
        name: 'stores',
        meta: {
            title: 'Магазины',
        },
        component: StoresView
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            title: 'Товары',
        },
        component: ProductsView
    },
    {
        path: '/match',
        name: 'match',
        meta: {
            title: 'Карточки',
        },
        component: MatchView
    },
    {
        path: '/wb-cards',
        name: 'wb-cards',
        meta: {
            title: 'Объединение ВБ',
        },
        component: WbCardsView
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            title: 'Настройки',
        },
        component: SettingsView
    },
    {
        path: '/suppliers',
        name: 'suppliers',
        meta: {
            title: 'Поставщики',
        },
        component: SuppliersView
    },
    {
        path: '/rules',
        name: 'rules',
        meta: {
            title: 'Правила',
        },
        component: RulesView
    },
    {
        path: '/templates',
        name: 'templates',
        meta: {
            title: 'Шаблоны',
        },
        component: TemplateView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.afterEach((to) => {
    menu.update(to)
})

router.beforeEach(async (to) => {
    PageInfo.clear()
    document.title = to.meta.title
    if (to.name === 'login' || to.name === 'register') {
        return
    }
    if (!auth.checkAuth()) {
        await router.push({name: 'login'})
    }
})

export default router