<template>
  <LoadingBlock :loading="storeLoading">
    <form @submit.prevent="yandexSubmit">
      <div class="col mb-4">
        <label class="form-label">Название магазина</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <input type="hidden" name="company" v-model="item_data.company">
      <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
      <div class="col mb-4">
        <label class="form-label">ID кабинета (Business ID)</label>
        <input type="text" name="connection[business_id]" class="form-control" placeholder="" required
               v-model="item_data.connection.business_id">
      </div>
      <div class="col mb-4">
        <label class="form-label">ID магазина (Campaing ID)</label>
        <input type="text" name="connection[campaign_id]" class="form-control" placeholder="" required
               v-model="item_data.connection.campaign_id">
      </div>
      <div class="col mb-4">
        <label class="form-label">API Key</label>
        <input type="text" name="connection[api_key]" class="form-control" placeholder="" required
               v-model="item_data.connection.api_key">
      </div>
      <div class="col mb-4">
        <label class="form-label">Использовать в качестве артикула</label>
        <Select v-model="item_data.settings.code_to_use" :options="code_to_use_options"
                optionLabel="name" optionValue="value" placeholder="" style="width: 100%" />
      </div>
      <div class="col mb-4">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {user} from "@/data/user";
import {editStoreYandex, newStoreYandex} from "@/api/store";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {Select} from "primevue";

export default {
  name: "YandexForm",
  components: {LoadingBlock, Select},
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'yandex',
        connection: {
          business_id: '',
          campaign_id: '',
          api_key: ''
        },
        settings: {
          code_to_use: 'product_code'
        }
      },
      code_to_use_options: [
        {
          'name': 'Код товара',
          'value': 'product_code',
        },
        {
          'name': 'Код поставщика',
          'value': 'supplier_code',
        }
      ],
      storeLoading: true
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
    this.storeLoading = false
  },
  methods: {
    async yandexSubmit() {
      this.storeLoading = true
      let result
      let text = 'Магазин успешно создан'
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        result = await newStoreYandex(this.item_data)
      } else {
        result = await editStoreYandex(this.item_data)
        text = 'Настройки магазина изменены'
      }
      const message = new Message()
      if (result.status === 200 || result.status === 201) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('storeSuccess')
      } else {
        message.text = 'Ошибка на стороне сервера, попробуйте попытку или обратитесь в поддержку.'
        message.type = 'failure'
      }
      Messages.list.push(message)
      this.storeLoading = false
    }
  }
}
</script>