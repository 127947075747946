import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getOffers(company_id, store_id, product_ids){
    const data = {
        store: store_id,
        products: product_ids,
        company_id: company_id
    }
    return await axios.post(
        `${API_URL}/v1/offer/list`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function update(company_id, offer){
    return await axios.post(
        `${API_URL}/v1/offer/update?company_id=${company_id}`,
        offer,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}


export async function create(company_id, offer){
    return await axios.post(
        `${API_URL}/v1/offer/create?company_id=${company_id}`,
        offer,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}


export async function upload(company_id, store_id, offers){
    return await axios.post(
        `${API_URL}/v1/offer/upload?company_id=${company_id}&store_id=${store_id}`,
        offers,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}



export async function wb_merge(company_id, store_id, offers){
    return await axios.post(
        `${API_URL}/v1/offer/wb-merge?company_id=${company_id}&store_id=${store_id}`,
        offers,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}


export async function getWbSubjects(store_id = '', data = {}) {
    return await axios.post(
        `${API_URL}/v1/offer/wb-unique-subjects?store_id=${store_id}`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}


export async function ozonAutoUpload(store_id = '', data = {}) {
    return await axios.post(
        `${API_URL}/v1/offer/ozon-auto-upload?store_id=${store_id}`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}