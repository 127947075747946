<template>
  <LoadingBlock :loading="loading">
    <form @submit.prevent="Submit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <input type="hidden" name="company_id" class="form-control" placeholder="" v-model="item_data.company_id">
      <div class="col mb-4">
        <label class="form-label">Название</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <div class="col mb-4">
        <label class="form-label">Настройки</label>
        <textarea name="settings" class="form-control" cols="30" rows="10" v-model="item_data.settings">
        </textarea>
      </div>
      <LoadingBlock :loading="background_loading">
        <div class="col mb-4">
          <label class="form-label">Загрузка фонов</label>
          <select v-model="background_upload" class="form-control">
            <option value="primary">Главная</option>
            <option value="secondary">Вторая</option>
          </select>
          <input type="file" class="form-control" @change="setBackground"/>
        </div>
      </LoadingBlock>

      <div class="col mb-4 text-center mt-4">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {createItem, updateItem, uploadBackground} from "@/api/templates";
import {user} from "@/data/user";


export default {
  name: "TemplateForm",
  components: {LoadingBlock},
  computed: {
    settings() {
      return JSON.stringify(this.item_data.settings, null, 2)
    }
  },
  data() {
    return {
      item_data: {
        "id": "",
        "company_id": "",
        "name": "",
        "settings": "",
      },
      background_upload: 'primary',
      background_loading: false,
      background_file: '',
      loading: false
    }
  },

  methods: {
    async Submit() {
      this.loading = true
      const message = new Message()
      const data = {...this.item_data}
      data.settings = JSON.parse(this.item_data.settings)
      if (this.item_data.id) {
        try {
          const result = await updateItem(this.item_data.id, data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Настройки успешно применены'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      } else {
        try {
          this.item_data.company_id = await user.getActiveCompany()
          const result = await createItem(data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Шаблон успешно создан'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      }
      Messages.list.push(message)
      this.loading = false
    },
    updateItem(item) {
      this.item_data = {...item}
      this.item_data.settings = JSON.stringify(this.item_data.settings, null, 2)
    },
    async setBackground(event) {
      this.background_loading = true
      const result = await uploadBackground(event.target.files[0])
      const file_name = result.data.name
      let settings = {}
      try {
        settings = JSON.parse(this.item_data.settings)
      } catch (e){
        settings = {}
      }
      if (this.background_upload === 'primary') {
        settings.primary_background = file_name
      }
      if (this.background_upload === 'secondary') {
        settings.secondary_background = file_name
      }
      this.item_data.settings = JSON.stringify(settings, null, 2)
      this.background_loading = false
    }
  },
}
</script>