import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getInfo(query){
    const queryString = new URLSearchParams(query).toString();
    return await axios.get(
        `${API_URL}/v1/store/marketplace/get-info?${queryString}`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}