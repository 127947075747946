<template>
  <LoadingBlock :loading="productLoading">
    <form @submit.prevent="productSubmit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <div class="col mb-4">
        <label class="form-label">Название товара</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <input type="hidden" name="company" class="form-control" placeholder="" required v-model="item_data.company">
      <div class="col mb-4">
        <label class="form-label">Внутренний код товара (артикул продавца)</label>
        <input type="text" name="code" class="form-control" placeholder="" required v-model="item_data.code">
      </div>
      <div class="col mb-4">
        <label class="form-label">Поставщик</label>
        <input type="text" name="code" class="form-control" placeholder="" required v-model="item_data.supplier_id">
      </div>
      <div class="col mb-4">
        <label class="form-label">Код поставщика</label>
        <input type="text" name="code" class="form-control" placeholder="" required v-model="item_data.supplier_code">
      </div>
      <div class="col mb-4">
        <label class="form-label">Цена</label>
        <input type="text" name="price" class="form-control" placeholder="" required v-model="item_data.price">
      </div>
      <div class="col mb-4">
        <label class="form-label">Бренд</label>
        <input type="text" name="brand" class="form-control" placeholder="" v-model="item_data.brand">
      </div>
      <div class="col mb-4">
        <label class="form-label">Штрихкод</label>
        <input type="text" name="brand" class="form-control" placeholder="" v-model="item_data.barcode">
      </div>
      <div class="col mb-4">
        <label class="form-label">Описание</label>
        <input type="text" name="brand" class="form-control" placeholder="" v-model="item_data.description">
      </div>
      <div class="col mb-4">
        <label class="form-label">Категория</label>
        <input type="text" name="category" class="form-control" placeholder="" v-model="item_data.category">
      </div>
      <div class="col mb-4">
        <label class="form-label">Ширина (мм)</label>
        <input type="text" name="width_mm" class="form-control" placeholder="" v-model="item_data.width_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Высота (мм)</label>
        <input type="text" name="height_mmm" class="form-control" placeholder="" v-model="item_data.height_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Длина (мм)</label>
        <input type="text" name="length_mm" class="form-control" placeholder="" v-model="item_data.length_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Вес (г)</label>
        <input type="text" name="weight_gr" class="form-control" placeholder="" v-model="item_data.weight_gr">
      </div>
      <div class="col mb-4">
        <label class="form-label">Остаток</label>
        <input type="text" name="quantity" class="form-control" placeholder="" v-model="item_data.quantity">
      </div>
      <div class="col mb-4">
        <div>
          <label class="form-label">Изображения</label>
        </div>
        <template v-for="(image, index) in item_data.images" :key="index">
          <div class="input-group mb-2">
            <input type="text" class="form-control" v-model="image.url" placeholder="Введите URL изображения">
            <button class="btn btn-danger" @click.prevent="removeImage(index)">Удалить</button>
          </div>
        </template>
        <button class="btn btn-primary" @click.prevent="addImage">Добавить изображение</button>
      </div>

      <div class="col mb-4">
        <div>
          <label class="form-label">Атрибуты</label>
        </div>
        <template v-for="(attribute, index) in item_data.attributes" :key="index">
          <div class="input-group mb-2">
            <input type="text" class="form-control" v-model="attribute.name" placeholder="Название атрибута">
            <input type="text" class="form-control" v-model="attribute.value" placeholder="Значение атрибута">
            <button class="btn btn-danger" @click.prevent="removeAttribute(index)">Удалить</button>
          </div>
        </template>
        <button class="btn btn-primary" @click.prevent="addAttribute">Добавить атрибут</button>
      </div>

      <div class="col mb-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="item_data.disabled" id="disable-check">
          <label class="form-check-label" for="disable-check"> Скрыть из поиска</label>
        </div>
      </div>

      <div class="col mb-4 text-center">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {user} from "@/data/user";
import {newProduct, updateProduct} from "@/api/product";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";


export default {
  name: "ProductForm",
  components: {LoadingBlock},
  data() {
    return {
      item_data: {
        id: '',
        company: '',
        code: '',
        supplier_code: '',
        name: '',
        quantity: '',
        category: '',
        description: '',
        brand: '',
        barcode: '',
        width_mm: '',
        height_mm: '',
        length_mm: '',
        weight_gr: '',
        images: [],
        attributes: [],
        disabled: ''
      },
      productLoading: false,
    }
  },
  methods: {
    async productSubmit() {
      let text
      let result
      this.productLoading = true
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        result = await newProduct(this.item_data)
        text = 'Товар успешно создан'
      } else {
        result = await updateProduct(this.item_data.id, this.item_data)
        text = 'Данные товара обновлены'
      }
      const message = new Message()
      if (result.status === 200 || result.status === 201) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('productSuccess')
      } else {
        message.type = 'failure'
        message.text = 'При создании/обновлении товара ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
      }
      Messages.list.push(message)
      this.productLoading = false
    },
    updateItem(item) {
      this.item_data = item
    },
    addImage() {
      if (!this.item_data.images) {
        this.item_data.images = []; // Если не существует, инициализируем его
      }
      this.item_data.images.push({url: ''}); // Добавляем новое пустое изображение
    },
    removeImage(index) {
      this.item_data.images.splice(index, 1); // Удаляем изображение по индексу
    },
    addAttribute() {
      if (!this.item_data.attributes) {
        this.item_data.attributes = []; // Если не существует, инициализируем его
      }
      this.item_data.attributes.push({name: '', value: ''}); // Добавляем новый пустой атрибут
    },
    removeAttribute(index) {
      if (this.item_data.attributes && this.item_data.attributes.length > 0) {
        this.item_data.attributes.splice(index, 1); // Удаляем атрибут по индексу
      }
    },
  }
}
</script>