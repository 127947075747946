<template>
  <LoadingBlock :loading="storeLoading">
    <form @submit.prevent="wbSubmit">
      <div class="col mb-4">
        <label class="form-label">Название магазина</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <input type="hidden" name="company" v-model="item_data.company">
      <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
      <div class="col mb-4">
        <label class="form-label">Токен</label>
        <input type="text" name="connection[token]" class="form-control" placeholder="" required
               v-model="item_data.connection.token">
      </div>
      <div class="col mb-4">
        <label class="form-label">Использовать в качестве артикула</label>
        <Select v-model="item_data.settings.code_to_use" :options="code_to_use_options"
                optionLabel="name" optionValue="value" placeholder="" style="width: 100%"/>
      </div>
      <div class="col mb-4">
        <label class="form-label">НДС</label>
        <Select v-model="item_data.settings.vat" :options="vat_options"
                optionLabel="name" optionValue="value" placeholder="" style="width: 100%"/>
      </div>
      <div class="col mb-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="item_data.settings.brand_remove_from_name" id="brand-check">
          <label class="form-check-label" for="brand-check"> Убирать бренд из названия товара</label>
        </div>
      </div>
      <div class="col mb-4">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {editStoreWb, newStoreWb} from "@/api/store";
import {user} from "@/data/user";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {Select} from "primevue";
import {code_to_use_options, vat_options} from "@/data/store";

export default {
  name: "WbForm",
  components: {LoadingBlock, Select},
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'wb',
        connection: {
          token: '',
        },
        settings: {
          code_to_use: 'product_code',
          brand_remove_from_name: true,
          vat: 'no_vat',
        }
      },
      storeLoading: true
    }
  },
  computed: {
    code_to_use_options() {
      return code_to_use_options
    },
    vat_options() {
      return vat_options
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
    this.storeLoading = false
  },
  methods: {
    async wbSubmit() {
      this.storeLoading = true
      let result
      let text = 'Магазин успешно создан'
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        result = await newStoreWb(this.item_data)
      } else {
        result = await editStoreWb(this.item_data)
        text = 'Настройки магазина изменены'
      }
      const message = new Message()
      if (result.status === 200 || result.status === 201) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('storeSuccess')
      } else {
        message.text = 'Ошибка на стороне сервера, попробуйте попытку или обратитесь в поддержку.'
        message.type = 'failure'
      }
      Messages.list.push(message)
      this.storeLoading = false
    }
  }
}
</script>