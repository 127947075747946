import {Message, Messages} from "@/data/messages";

export function afterStoreAction(result) {
    if (typeof result.data === undefined || !isNaN(result.data)) {
        if (result.status === 200) {
            let message = new Message()
            message.text = 'Задача успешно выполнена'
            message.type = 'success'
            message.format = 'notification'
            message.autoremove = 10
            Messages.list.push(message)
        } else {
            let message = new Message()
            message.text = 'Произошла ошибка на стороне сервера'
            message.type = 'failure'
            message.format = 'notification'
            Messages.list.push(message)
        }
        return
    }
    if (result.data['info']) {
        let message = new Message()
        message.text = result.data['info']
        message.type = 'success'
        message.format = 'notification'
        message.autoremove = 10
        Messages.list.push(message)
    }
    if (result.data['error']) {
        let message = new Message()
        message.text = result.data['error']
        message.type = 'failure'
        message.format = 'notification'
        Messages.list.push(message)
    }
    if (result.status !== 200 && result.data['detail']) {
        let message = new Message()
        message.text = result.data['detail']
        message.type = 'failure'
        message.format = 'notification'
        Messages.list.push(message)
    }
    if (result.data['warning']) {
        let message = new Message()
        message.text = result.data['warning']
        message.type = 'warning'
        message.format = 'notification'
        Messages.list.push(message)
    }
}