import {reactive} from 'vue'
import {getStores} from "@/api/store";
import {user} from "@/data/user";

export const stores = reactive({
    stores: [],
    activeStore: [],

    async getStores() {
        if (this.stores.length) {
            return this.stores
        }
        await this.updateStores()
        return this.stores
    },
    async updateStores() {
        const active_company = await user.getActiveCompany()
        const result = await getStores(active_company)
        this.stores = result.data.items
        this.stores.forEach((item) => item.icon = getMarketplaceIcon(item.marketplace))
    },
    async getActiveStore() {
        if (!this.activeStore.length && localStorage.getItem('active_store')) {
            const storage_store = JSON.parse(localStorage.getItem('active_store'))
            const active_company = await user.getActiveCompany()
            if (typeof storage_store[active_company] !== "undefined") {
                this.activeStore = storage_store[active_company]
            }
        }
        let all_stores = await this.getStores()
        all_stores = all_stores.map(store => store.id)
        if (!all_stores.includes(this.activeStore)) {
            this.activeStore = ''
        }
        return this.activeStore
    },
    async setActiveStore(store) {
        this.activeStore = store
        const active_company = await user.getActiveCompany()
        let storage_stores = JSON.parse(localStorage.getItem('active_store'))
        if (!storage_stores || storage_stores.constructor !== Object) {
            storage_stores = {}
        }
        storage_stores[active_company] = store
        localStorage.setItem('active_store', JSON.stringify(storage_stores))
    },
    resetStores() {
        this.stores = []
        this.activeStore = []
    }

})

export const Marketplaces = {
    yandex: {
        name: 'Яндекс Маркет',
        icon: require('@/assets/images/marketplaces/yandex.svg'),
        code: 'yandex'
    },
    wb: {
        name: 'Wildberries',
        icon: require('@/assets/images/marketplaces/wb.svg'),
        code: 'wb'
    },
    ozon: {
        name: 'Озон',
        icon: require('@/assets/images/marketplaces/ozon.svg'),
        code: 'ozon'
    }
}

export function getMarketplaceIcon(marketplace_code) {
    const marketplace = Marketplaces[marketplace_code]
    if (typeof marketplace !== 'undefined') {
        return marketplace.icon
    }
    return ''
}

export const code_to_use_options = [
    {
        'name': 'Код товара',
        'value': 'product_code',
    },
    {
        'name': 'Код поставщика',
        'value': 'supplier_code',
    }
]

export const vat_options = [
    {
        'name': 'Без НДС',
        'value': 'no_vat',
    },
    {
        'name': '0%',
        'value': 'vat_0',
    },
    {
        'name': '10%',
        'value': 'vat_10',
    },
    {
        'name': '20%',
        'value': 'vat_20',
    },
]