<template>
  <LoadingBlock :loading="loading">
    <form @submit.prevent="Submit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <input type="hidden" name="company_id" class="form-control" placeholder="" v-model="item_data.company_id">
      <div class="col mb-4">
        <label class="form-label">Название поставщика</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <div class="col mb-4 text-center">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {createItem, updateItem} from "@/api/supplier";
import {user} from "@/data/user";


export default {
  name: "SupplierForm",
  components: {LoadingBlock},
  data() {
    return {
      item_data: {},
      loading: false
    }
  },
  methods: {
    async Submit() {
      this.loading = true
      const message = new Message()
      if (this.item_data.id) {
        try {
          const result = await updateItem(this.item_data.id, this.item_data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Настройки успешно применены'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      }else{
        try {
          this.item_data.company_id = await user.getActiveCompany()
          const result = await createItem(this.item_data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Поставщик успешно создан'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      }
      Messages.list.push(message)
      this.loading = false
    },
    updateItem(item) {
      this.item_data = item
    },
  },
}
</script>