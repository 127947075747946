<template>
  <LoadingBlock :loading="loading">
    <form @submit.prevent="Submit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <input type="hidden" name="company_id" class="form-control" placeholder="" v-model="item_data.company_id">
      <div class="col mb-4">
        <label class="form-label">Поставщик</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.supplier_id">
      </div>
      <div class="col mb-4">
        <label class="form-label">Маркетплейс</label>
        <Select v-model="item_data.marketplace" :options="marketplaces" optionLabel="name" optionValue="code"
                style="width: 100%">
        </Select>
      </div>
      <div class="col mb-4">
        <label class="form-label">Тип правила</label>
        <Select v-model="item_data.rule_type" :options="rule_types" optionLabel="name" optionValue="code"
                style="width: 100%">
        </Select>
      </div>
      <div class="col mb-4">
        <label class="form-label">Информация поставщика</label>
        <input type="text" name="supplier_info" class="form-control" placeholder="" required
               v-model="item_data.supplier_info">
      </div>
      <div class="col mb-4">
        <label class="form-label">Информация маркетплейса</label>
        <input type="text" name="marketplace_info" class="form-control" placeholder="" required
               v-model="item_data.marketplace_info">
      </div>

      <div v-for="(action, index) in item_data.actions" :key="index" class="action-row mb-3">
        <div class="row">
          <div class="col">
            <label class="form-label">Тип действия</label>
            <Select v-model="action.type" :options="action_types" optionLabel="name" optionValue="code"
                    style="width: 100%">
            </Select>
          </div>
          <div class="col">
            <label class="form-label">Число</label>
            <input type="number" class="form-control" v-model="action.value_float" placeholder="Введите значение"
                   >
          </div>
          <div class="col">
            <label class="form-label">Словарь</label>
            <input type="text" class="form-control" v-model="actionValueDict[index]"
                   @blur="updateActionValueDict(index)"
                   placeholder="Введите значение в формате словаря">
          </div>
          <div class="col-auto d-flex align-items-end">
            <button type="button" class="btn btn-danger" @click="removeAction(index)">Удалить</button>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary" @click="addAction">Добавить действие</button>
      <div class="col mb-4 text-center">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";
import {createItem, updateItem} from "@/api/rule";
import {user} from "@/data/user";
import {Select} from "primevue";
import {stores} from "@/data/store";


export default {
  name: "RuleForm",
  computed: {
    stores() {
      return stores
    },
    actionValueDict() {
      return this.item_data.actions.map(action =>
        JSON.stringify(action.value_dict)
      );
    }
  },
  components: {LoadingBlock, Select},
  data() {
    return {
      item_data: {
        "id": "",
        "company_id": "",
        "supplier_id": "",
        "marketplace": "",
        "rule_type": "",
        "supplier_info": "",
        "marketplace_info": "",
        "actions": []
      },
      marketplaces: [
        {name: 'ВБ', code: 'wb'},
        {name: 'Яндекс Маркет', code: 'yandex'},
        {name: 'Озон', code: 'ozon'},
      ],
      rule_types: [
        {name: 'Категория', code: 'category'},
        {name: 'Характеристика', code: 'attribute'},
      ],
      action_types: [
        {name: 'Из словаря', code: 'from_dict'},
        {name: 'Умножить', code: 'multiply'},
        {name: 'Разделить', code: 'divide'},
      ],
      loading: false
    }
  },

  methods: {
    updateActionValueDict(index) {
      try {
        this.item_data.actions[index].value_dict = JSON.parse(this.actionValueDict[index]);
      } catch (e) {
        console.error("Некорректный формат записи словаря:", e);
      }
    },
    parseDict(dictString) {
      try {
        // Используем JSON.parse, чтобы парсить строку в словарь. Предполагается, что на вход передается валидная строка.
        return JSON.parse(dictString.replace(/([{,])\s*(\w+)\s*:/g, '$1"$2":').replace(/'/g, '"'));
      } catch (e) {
        console.error('Ошибка при парсинге строки в словарь:', e);
        return {};
      }
    },
    async Submit() {
      this.loading = true
      const message = new Message()
      if (this.item_data.id) {
        try {
          const result = await updateItem(this.item_data.id, this.item_data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Настройки успешно применены'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      } else {
        try {
          this.item_data.company_id = await user.getActiveCompany()
          const result = await createItem(this.item_data)
          if (result.status === 200 || result.status === 201) {
            message.type = 'success'
            message.autoremove = 10
            message.text = 'Правило успешно создано'
            this.$emit('success', this.item_data)
          } else {
            message.type = 'failure'
            message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
          }
        } catch (e) {
          message.type = 'failure'
          message.text = 'При создании/обновлении произошла ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
        }
      }
      Messages.list.push(message)
      this.loading = false
    },
    updateItem(item) {
      this.item_data = {...item}
    },
    addAction() {
      if (!this.item_data.actions) {
        this.item_data.actions = []
      }
      this.item_data.actions.push({type: '', value_float: '', value_dict: {}});
    },
    removeAction(index) {
      this.item_data.actions.splice(index, 1);
    }
  },
}
</script>