import {reactive} from 'vue'
import {getList} from "@/api/templates";
import {user} from "@/data/user";

export const templates = reactive({
    templates: [],
    chosen_template: '',
    secondary_count: 2,
    loading: false,
    isInitialized: false,

    async getTemplates() {
        if (!this.isInitialized) {
            await this.setTemplates();
        }
        return this.templates;
    },

    async setTemplates() {
        if (!this.loading && !this.isInitialized) {
            this.loading = true;  // Устанавливаем индикатор загрузки
            try {
                const company_id = await user.getActiveCompany();  // Получаем ID компании
                const result = await getList(company_id, '');  // Запрашиваем список шаблонов
                if (result.data.items) {
                    this.templates = result.data.items;  // Устанавливаем полученные шаблоны
                    this.isInitialized = true;  // Изменяем статус на инициализированный
                }
            } catch (error) {
                console.error('Ошибка загрузки шаблонов:', error);
            } finally {
                this.loading = false;  // Отключаем индикатор загрузки, независимо от результата
            }
        }
    },
});