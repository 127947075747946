<template>
  <BaseLayout>
    <div class="card card-fullscreen">
      <div class="card-body">
        <LoadingBlock :loading="loadingSettings">
          <div class="row gy-sm-1">
            <div class="col-md-3 mt-2">
              <label class="form-label">Поиск по товарам</label>
              <input type="text" class="form-control border-1 py-2" placeholder="Название, код товара, бренд"
                     v-model="searchData.name" @change="search">
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Поиск по бренду</label>
              <input type="text" class="form-control border-1 py-2" placeholder="Название бренда"
                     v-model="searchData.brand" @change="search">
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Магазин</label>
              <Select v-model="activeStore" :options="stores" optionLabel="name" optionValue="id"
                      @change="setActiveStore"
                      placeholder="Магазин не выбран"
                      style="width: 100%">
              </Select>
            </div>
            <div class="col-md-3 mt-2">
              <label class="form-label">Фильтр</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="stock-ckeck" v-model="searchData.in_stock"
                       @change="search">
                <label class="form-check-label" for="stock-ckeck">Есть в наличии</label>
              </div>
              <div class="form-check form-switch mb-2">
                <input class="form-check-input" type="checkbox" id="store-check"
                       v-model="searchData.has_not_sent_offer_in_store" @change="search">
                <label class="form-check-label" for="store-check">Не выгружены в выбранный магазин</label>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Размер страницы</label><br>
              <Select v-model="searchData.size" :options="pageSizes" optionLabel="name" optionValue="value"
                      @change="search"
              >
              </Select>
            </div>
            <div class="col-md-3 mt-2">
              <label class="form-label">Массовые действия</label>
              <div>
                <button class="btn btn-outline-primary"
                        @click="setBatchAction('WbDescription')"
                        :disabled="batchDisabled.includes('WbDescription')">
                  AI описание
                </button>
              </div>
              <div>
                <button class="btn btn-outline-primary mt-1" v-if="activeMarketplace === 'wb'"
                        @click="setBatchAction('WbAttributes')"
                        :disabled="batchDisabled.includes('WbAttributes')">
                  AI характеристики
                </button>
              </div>
              <div>
                <button class="btn btn-outline-primary mt-1" v-if="activeMarketplace === 'ozon'"
                        @click="setBatchAction('OzonAttributes')"
                        :disabled="batchDisabled.includes('OzonAttributes')">
                  AI характеристики
                </button>
              </div>
              <div>
                <button class="btn btn-outline-primary mt-1" v-if="activeMarketplace === 'ozon'"
                        @click="OzonAutoCreate()"
                        :disabled="batchDisabled.includes('OzonAutoCreate')">
                  Автоматическое создание согласно фильтрам
                </button>
              </div>
            </div>

          </div>
          <div v-if="!products.length && !loadingProducts" class="mt-7">
            Товары не найдены
          </div>
        </LoadingBlock>
      </div>
      <div class="card-body">
        <LoadingBlock :loading="loadingOffers || loadingProducts">
          <template v-for="(product, index) in products" :key="index">
            <div class="row product-row">
              <div class="col-lg-6">
                <MatchProduct :item="product" @attribute-selected="updateProductAttribute"
                              @product-changed="editProduct"/>
              </div>
              <div class="col-lg-6">
                <BaseOffer
                    :offer="offers[product.id]"
                    :marketplace="activeMarketplace"
                    :product="product"
                    :product-attribute="productAttribute"
                    :batch-action="batchAction"
                    @new-rule="updateOffersList"
                />
              </div>
            </div>
          </template>

          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
          </BasePagination>
        </LoadingBlock>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {stores} from "@/data/store";
import {getProducts, updateProduct} from "@/api/product";
import BasePagination from "@/components/list/Pagination.vue"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {getOffers, ozonAutoUpload} from "@/api/offer";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Select} from "primevue";
import MatchProduct from "@/components/match/Product.vue";
import BaseOffer from "@/components/match/BaseOffer.vue";

export default {
  name: 'MatchView',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    BaseOffer,
    MatchProduct,
    LoadingBlock, BaseLayout, BasePagination, Select
  },
  emits: ['batch-action'],
  data() {
    return {
      stores: [],
      loadingSettings: true,
      loadingOffers: false,
      loadingProducts: true,
      activeStore: [],
      activeMarketplace: '',
      products: [],
      offers: {},
      paginationInfo: {},
      searchData: {
        name: '',
        brand: '',
        has_not_sent_offer_in_store: true,
        in_stock: true,
        size: '20',
      },
      productAttribute: null,
      batchAction: null,
      batchDisabled: [],
      pageSizes: [{name: '10', value: '10'}, {name: '20', value: '20'}, {name: '50', value: '50'}, {
        name: '100',
        value: '100'
      }]
    }
  },
  methods: {
    async getProducts() {
      this.products = []
      this.batchDisabled = []
      this.loadingProducts = true
      const result = await getProducts(user.active_company, this.$route.query, this.activeStore)
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
      this.products = result.data.items
      this.products.forEach(item => item.is_updating = false)
      this.loadingProducts = false
    },
    async search() {
      const query = {...this.$route.query}
      delete query.page
      Object.entries(this.searchData).forEach(([key, value]) => {
        if (value) {
          query[key] = value
        } else {
          delete query[key]
        }
      });
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...query
        }
      })
    },
    setSearch() {
      if (this.$route.query.name) {
        this.searchData.name = this.$route.query.name
      } else {
        this.searchData.name = ''
      }
      if (this.$route.query.brand) {
        this.searchData.brand = this.$route.query.brand
      } else {
        this.searchData.brand = ''
      }
      if (this.$route.query.size) {
        this.searchData.size = this.$route.query.size
      } else {
        this.searchData.size = '20'
      }
      if (this.$route.query.in_stock) {
        this.searchData.in_stock = this.$route.query.in_stock
      } else {
        this.searchData.in_stock = ''
      }
      if (this.$route.query.has_not_sent_offer_in_store) {
        this.searchData.has_not_sent_offer_in_store = this.$route.query.has_not_sent_offer_in_store
      } else {
        this.searchData.has_not_sent_offer_in_store = ''
      }
    },
    async changePage(page) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page
        }
      });
    },
    setPageInfo() {
      PageInfo.title = 'Карточки'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    async setActiveStore() {
      await stores.setActiveStore(this.activeStore)
      const store = this.stores.filter((store) => store.id === this.activeStore)[0]
      this.activeMarketplace = store.marketplace
      await this.loadOffers()
    },
    async loadOffers(products = null) {
      this.offers = []
      if (!products) {
        products = this.products.map((product) => product.id)
      }
      if (!products.length || !this.activeStore) {
        return
      }

      this.loadingOffers = true
      const company_id = await user.getActiveCompany()
      const offers = await getOffers(company_id, this.activeStore, products)
      this.offers = []
      offers.data.forEach((offer) => this.offers[offer.product_id] = offer)
      this.loadingOffers = false
    },
    updateProductAttribute(attribute) {
      this.productAttribute = attribute;
    },
    async updateOffersList() {
      await this.loadOffers()
    },
    setBatchAction(action) {
      this.batchAction = action
      this.batchDisabled.push(action)
      setTimeout(() => this.batchAction = null, 100)
    },
    async OzonAutoCreate() {
      this.batchDisabled.push('OzonAutoCreate')
      const data = {...this.searchData}
      data.company = await user.getActiveCompany()
      await ozonAutoUpload(
          this.activeStore,
          data
      )
    },
    async editProduct(new_data, save = true) {
      const index = this.products.findIndex(item => item.id === new_data.id)
      this.products[index] = {...new_data}
      if (save) {
        this.products[index].is_updating = true
        await updateProduct(this.products[index].id, this.products[index])
        this.products[index].is_updating = false
      }
    }
  },
  async mounted() {
    this.setPageInfo()
    this.setSearch()
    await user.getUserBase()
    this.stores = await stores.getStores()
    this.activeStore = await stores.getActiveStore()
    this.loadingSettings = false
    await this.getProducts()
    await this.setActiveStore()
  },
  watch: {
    async '$route'() {
      this.setSearch()
      this.setPageInfo()
      await this.getProducts()
      await this.setActiveStore()
    }
  }
}
</script>

<style>
.product-row {
  margin: 10px 0;
  border: 2px #eee solid;
  padding: 5px;
  border-radius: 2px;
}
</style>
