import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function description(data) {
    return await axios.post(
        `${API_URL}/v1/gpt/description`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}


export async function wb_attributes(data) {
    return await axios.post(
        `${API_URL}/v1/gpt/wb-attributes`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}


export async function ozon_attributes(data) {
    return await axios.post(
        `${API_URL}/v1/gpt/ozon-attributes`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}
