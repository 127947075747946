<template>
  <LoadingBlock :loading="item['is_updating']">
    <h5 class="mt-5">{{ item['name'] }}</h5>
    <p v-if="displayText" class="mt-10 mb-10">
      {{ displayText }}
      <a href="#" v-if="!isExpanded" @click.prevent="toggleExpand">Показать</a>
      <a href="#" v-if="isExpanded" @click.prevent="toggleExpand">Скрыть</a>
    </p>
    <table class="table">
      <tbody>
      <tr>
        <td><b>Категория</b></td>
        <td>{{ item['category'] }}</td>
      </tr>
      <tr>
        <td><b>Бренд</b></td>
        <td>{{ item['brand'] }}</td>
      </tr>
      <tr>
        <td><b>Габариты (мм)</b></td>
        <td>{{ item['width_mm'] }} * {{ item['height_mm'] }} * {{ item['length_mm'] }}</td>
      </tr>
      <tr>
        <td><b>Количество фото</b></td>
        <td><a href="#" @click.prevent="showPhotos">{{ item['images'].length }}</a></td>
      </tr>
      <tr>
        <td><b>Инфографика</b></td>
        <td><a href="#" @click.prevent="showInfographics">{{ getInfographicsLength() }}</a></td>
      </tr>
      <tr v-for="(attribute, index) in item['attributes']" :key="index">
        <td :class="{ selected: selectedAttribute === attribute.name }"
            @click="selectAttribute(attribute.name)">
          <span>{{ attribute['name'] }}</span>
        </td>
        <td>{{ attribute['value'] }}</td>
      </tr>
      </tbody>
    </table>
    <BaseModal modalTitle="Фотографии" ref="imagesModal">
      <div v-for="(image, index) in images" :key="index" class="mt-2">
        <img :src="image.url" alt="" width="100%">
      </div>
    </BaseModal>
    <BaseModal modalTitle="Инфографика" ref="infographicsModal">
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">Шаблон</label>
          <Select v-model="chosen_template" optionLabel="name" optionValue="id" :options="templates"
                  style="width: 100%;"/>
        </div>
        <div class="col">
          <label class="form-label">Доп. карточки</label>
          <input type="text" v-model="secondary_count" class="form-control"/>
        </div>
        <div class="col">
          <label class="form-label">&nbsp;</label>
          <ButtonComponent ref="GenerateButton" class="btn btn-primary" @click="generateInfographics">
            Сгенерировать
          </ButtonComponent>
        </div>
      </div>
      <div v-for="(item, index) in infographics" :key="index" class="mt-2 infographics_item">
        <button class="btn btn-danger" @click="deleteInfographics(item.file_name)">
          <span class="ti ti-trash ti-md"></span>
        </button>
        <img :src="item.file_url" alt="" width="100%">
      </div>
    </BaseModal>
  </LoadingBlock>
</template>

<script>

import BaseModal from "@/components/Modal.vue";
import {Select} from "primevue";
import {templates} from "@/data/infographics";
import {generateSingle} from "@/api/infographics";
import ButtonComponent from "@/components/tools/Button.vue";
import LoadingBlock from "@/components/page/LoadingBlock.vue";

export default {
  name: "MatchProduct",
  components: {BaseModal, Select, ButtonComponent, LoadingBlock},
  data() {
    return {
      images: [],
      infographics: [],
      selectedAttribute: null,
      isExpanded: false,
      chosen_template: '',
      templates: [],
      secondary_count: 2
    }
  },
  computed: {
    displayText() {
      const description = this.item.description;
      if (!this.isExpanded) {
        return description.length > 100 ? description.substring(0, 100) + '...' : description;
      }
      return description;
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  emits: ['attribute-selected', 'product-changed'],
  methods: {
    showPhotos() {
      this.images = this.item.images
      this.$refs.imagesModal.openModal()
    },
    async showInfographics() {
      this.templates = await templates.getTemplates()
      this.chosen_template = templates.chosen_template
      this.secondary_count = templates.secondary_count
      this.infographics = this.item.infographics
      this.$refs.infographicsModal.openModal()
    },
    selectAttribute(attributeName) {
      this.selectedAttribute = attributeName;
      this.$emit('attribute-selected', attributeName)
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    getInfographicsLength() {
      if (this.item.infographics) {
        return this.item.infographics.length
      } else {
        return 0
      }
    },
    async generateInfographics() {
      if (!this.chosen_template) {
        alert('Шаблон не выбран')
        return
      }
      this.$refs.GenerateButton.startLoading()
      const data = {
        template_id: this.chosen_template,
        product_id: this.item.id,
        secondary_count: this.secondary_count
      }
      const res = await generateSingle(data)
      this.$refs.GenerateButton.endLoading()
      this.infographics = res.data
      const new_product_data = {...this.item}
      new_product_data.infographics = res.data
      this.$emit('product-changed', new_product_data, false)
    },
    async deleteInfographics(file_name) {
      this.infographics = this.infographics.filter(item => item['file_name'] !== file_name)
      const new_product_data = {...this.item}
      new_product_data.infographics = this.infographics
      this.$emit('product-changed', new_product_data, true)
    }
  },
  watch: {
    chosen_template: {
      handler(new_value) {
        templates.chosen_template = new_value
      }
    },
    secondary_count: {
      handler(new_value) {
        templates.secondary_count = new_value
      }
    }
  }
}
</script>

<style scoped>
.table td:first-child {
  width: 250px;
  cursor: pointer;
}

.table td.selected span {
  border: 2px #00a7bc solid;
}

.table td span {
  padding: 2px;
}
.infographics_item{
  position: relative;
}
.infographics_item button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>