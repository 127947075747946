<template>

  <h6>
    <input v-if="offer !== undefined && offer['store_info'] && offer['store_info']['imt_id']" type="checkbox"
           v-model="checked" class="form-check-input">
    {{ product.name }}</h6>
  <p>
    Артикул: {{product.code}}
  </p>
  <div v-if="offer !== undefined">
    <div v-if="offer['store_url']">
      <a :href="offer['store_url']" target="_blank">Перейти на карточку</a>
    </div>
    <span v-if="offer['marketplace_category']">
        {{ offer['marketplace_category']['name'] }}
    </span>
  </div>
</template>


<script>

export default {
  name: "WbCardBase",
  data() {
    return {
      checked: false
    }
  },
  props: {
    offer: {
      type: Object
    },
    product: {
      type: Object
    },
  },
  emits: ['check'],
  methods: {},
  watch: {
    offer: {
      deep: true,
      handler(newOffer) {
        if (newOffer !== undefined) {
          this.checked = newOffer.checked
        }
      }
    },
    checked: {
      handler(newValue) {
        console.log('aa')
        this.$emit('check', this.product.id, newValue)
      }
    }
  },
}
</script>