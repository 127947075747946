<template>
  <div>
    <h5 class="category mt-5" @click="openCategorySelect">
      Категория:
      <span v-if="offer_data['marketplace_category']">
        {{ offer_data['marketplace_category']['name'] }}
      </span>
      <span v-if="!offer_data['marketplace_category']">не выбрана</span>
    </h5>
    <template v-if="offer_data['marketplace_category']">
      <ButtonComponent class="btn btn-outline-secondary" @click="categoryRule()">
        Правило для категории
      </ButtonComponent>

      <ButtonComponent v-if="offer_data['marketplace_category']" class="btn btn-outline-secondary"
                       style="margin-left: 3px"
                       @click="attributeRule()">
        Правило для характеристик
      </ButtonComponent>
      <p v-if="offer_data['marketplace_data'] && !isExpanded" class="mt-10 mb-5"
         :class="descriptionByAi ? 'descr-by-ai' : ''">
        {{ displayText }}<br>
        <a href="#" v-if="!isExpanded" @click.prevent="toggleExpand">Показать</a>
      </p>
      <textarea v-if="isExpanded" class="form-control mt-5 mb-1" :class="descriptionByAi ? 'descr-by-ai' : ''"
                v-model="offer_data['marketplace_data']['description']" width="100%" rows="10"></textarea>
      <div v-if="isExpanded" class="mb-5">
        <a href="#" @click.prevent="toggleExpand" class="mb-2">Скрыть </a>
      </div>
      <ButtonComponent ref="descriptionButton" class="btn btn-outline-secondary" @click="generateDescription">AI
        Описание
      </ButtonComponent>
      <ButtonComponent ref="attributesButton" class="btn btn-outline-secondary" style="margin-left: 5px;"
                       @click="generateAttributes">AI
        Характеристики
      </ButtonComponent>
      <table class="table mt-5" v-if="offer_data['marketplace_data']">
        <tbody>
        <tr>
          <td><b>Наименование</b></td>
          <td>
            <input
                class="offer-input" type="text"
                v-model="offer_data['marketplace_data']['title']"
                maxlength="60"
                @focus="isEditingIndex = 'title'"
                @blur="isEditingIndex = null"
                :readonly="isEditingIndex !== 'title'"
            >
          </td>
        </tr>
        <tr>
          <td><b>Бренд</b></td>
          <td>{{ offer_data['marketplace_data']['brand'] }}</td>
        </tr>
        <tr>
          <td><b>Штрихкод</b></td>
          <td>
            <input
                class="offer-input" type="text"
                v-model="offer_data['marketplace_data']['barcode']"
                maxlength="120"
                @focus="isEditingIndex = 'barcode'"
                @blur="isEditingIndex = null"
                :readonly="isEditingIndex !== 'barcode'"
            >
          </td>
        </tr>
        <tr>
          <td><b>Габариты (cм)</b></td>
          <td>{{ offer_data['marketplace_data']['dimensions']['width'] }} *
            {{ offer_data['marketplace_data']['dimensions']['height'] }} *
            {{ offer_data['marketplace_data']['dimensions']['length'] }}
          </td>
        </tr>
        <tr v-for="(char, index) in offer_data['marketplace_data']['characteristics']" :key="index">
          <td :class="{ selected: selectedAttribute === char['id'] }"
              @click="selectAttribute(char['id'])">
            <b v-if="char['required']">{{ char['name'] }} *</b>
            <span v-if="!char['required']">{{ char['name'] }}</span>
          </td>
          <td>
            {{ char['error'] }}
            <input
                class="offer-input" type="text"
                v-model="char['value']"
                @focus="isEditingIndex = index"
                @blur="isEditingIndex = null; checkChar(char)"
                :readonly="isEditingIndex !== index"
                :class="{'by_ai_char': by_ai_chars.includes(char['id']) }"
            >
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
  <BaseModal modal-title="Выберите категорию ВБ" ref="wbCategorySelect">
    <LoadingBlock :loading="searchLoading">
      <input type="text" class="form-control border-1 py-2" placeholder="Введите название"
             v-model="category_search" @change="searchCategory">
      <div class="category_results">
        <p v-for="(result, index) in category_results" :key="index" @click="setCategory(index)">
          {{ result.value['parent_name'] }} - {{ result.value['subject_name'] }}
        </p>
      </div>
    </LoadingBlock>
  </BaseModal>
  <BaseModal ref="ruleModal" modal-title="Новое правило">
    <RuleForm ref="ruleForm" @success="ruleSuccess()">

    </RuleForm>
  </BaseModal>
</template>


<script>

import BaseModal from "@/components/Modal.vue";
import {getInfo} from "@/api/marketplace";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import ButtonComponent from "@/components/tools/Button.vue";
import RuleForm from "@/components/forms/Rule.vue";
import {user} from "@/data/user";
import {description, wb_attributes} from "@/api/gpt";

export default {
  name: "WbOffer",
  components: {RuleForm, ButtonComponent, LoadingBlock, BaseModal},
  data() {
    return {
      offer_data: {},
      category_search: '',
      category_results: [],
      searchLoading: false,
      selectedAttribute: null,
      isExpanded: false,
      isEditingIndex: null,
      descriptionByAi: false,
      by_ai_chars: [],
    }
  },
  computed: {
    displayText() {
      const description = this.offer_data['marketplace_data']['description'];
      if (!this.isExpanded) {
        return description.length > 100 ? description.substring(0, 100) + '...' : description;
      }
      return description;
    }
  },
  props: {
    offer: {
      type: Object
    },
    error_data: {
      type: Object,
      mutable: true
    },
    product: {
      type: Object
    },
    productAttribute: {
      type: String
    },
    batchAction: {
      type: String
    }
  },
  emits: ['new-rule', 'offer-change'],
  methods: {
    openCategorySelect() {
      this.$refs.wbCategorySelect.openModal()
    },
    async searchCategory() {
      this.searchLoading = true
      const data = {
        marketplace: 'wb',
        search_data: this.category_search,
        info_type: 'wb_objects'
      }
      const result = await getInfo(data)
      this.category_results = result.data.items
      this.searchLoading = false
    },
    setCategory(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.offer_data.marketplace_category = {
        name: this.category_results[index]['value']['parent_name'] + ' - ' + this.category_results[index]['value']['subject_name'],
        subject_id: this.category_results[index]['value']['subject_id']
      }
      this.$refs.wbCategorySelect.closeModal()
      this.$emit('offer-change', this.offer_data)
    },
    async categoryRule() {
      const company_id = await user.getActiveCompany()
      const rule = {
        company_id: company_id,
        supplier_id: this.product.supplier_id,
        marketplace: 'wb',
        rule_type: 'category',
        supplier_info: this.product.category,
        marketplace_info: this.offer_data.marketplace_category['subject_id'].toString(),
        actions: []
      }
      setTimeout(() => {
        this.$refs.ruleModal.openModal()
        this.$refs.ruleForm.updateItem(rule)
      }, 100)
    },
    ruleSuccess() {
      this.$refs.ruleModal.closeModal()
      this.$emit('new-rule')
    },
    selectAttribute(attributeName) {
      this.selectedAttribute = attributeName; // Update the selected attribute
    },
    async attributeRule() {
      if (!this.selectedAttribute) {
        alert('Выберите характеристику маркетплейса')
        return
      }
      if (!this.productAttribute) {
        alert('Выберите характеристику товара')
        return
      }
      const company_id = await user.getActiveCompany()
      const rule = {
        company_id: company_id,
        supplier_id: this.product.supplier_id,
        marketplace: 'wb',
        rule_type: 'attribute',
        supplier_info: this.productAttribute,
        marketplace_info: this.selectedAttribute.toString(),
        actions: []
      }
      setTimeout(() => {
        this.$refs.ruleModal.openModal()
        this.$refs.ruleForm.updateItem(rule)
      }, 100)
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },

    async generateDescription() {
      this.$refs.descriptionButton.startLoading()
      try {
        const result = await description({
          product_id: this.product.id,
          text: this.product.description,
          attributes: this.product.attributes
        })
        if (result.data.text) {
          this.offer_data['marketplace_data']['description'] = result.data.text
          this.descriptionByAi = true
        }
      } catch (e) {
        alert('Произошла ошибка')
      }
      this.$refs.descriptionButton.endLoading()
    },

    async generateAttributes() {
      this.$refs.attributesButton.startLoading()
      if (this.offer_data['marketplace_data']['characteristics'] === undefined) {
        alert('У оффера нет характеристик')
        return
      }
      const characteristics = this.offer_data['marketplace_data']['characteristics']
      const by_ai_chars = this.by_ai_chars
      const charsWithoutValues = characteristics.filter(
          (char) => !char.value
      )
      const data = {
        product_name: this.product.name,
        product_id: this.product.id,
        product_description: this.product.description,
        product_attributes: this.product.attributes,
        wb_characteristics: charsWithoutValues,
      }
      try {
        const result = await wb_attributes(data)
        if (result.data['wb_characteristics']) {
          result.data['wb_characteristics'].forEach(function (char) {
            const id = char['id']
            const offer_char = characteristics.find(
                (char) => char.id === id
            )
            if (offer_char !== undefined && char.value) {
              offer_char.value = char.value
              by_ai_chars.push(char.id)
            }
          })
        }
      } catch (e) {
        alert('Произошла ошибка')
      }
      this.$refs.attributesButton.endLoading()
    },
    checkChar(char) {
      let error = ''
      if (char['value'] && char['value'].length) {
        const value = char['value']
        if (char['max_count'] > 1) {
          const count = value.split(';').length
          if (count > char['max_count']) {
            error += '\nОшибка: можно указать не более ' + char['max_count'] + ' значений'
          }
        }
        const items = value.split(';');
        items.forEach((item) => {
          if (item.length > 100) {
            error += '\nОшибка: нельзя использовать больше 100 символов - ' + item
          }
        })
      }
      char['error'] = error
    },
    checkForErrors() {
      delete this.error_data['char']
      if (this.offer_data['marketplace_data']) {
        let char_errors = false
        this.offer_data['marketplace_data']['characteristics'].forEach((char) => {
          if (char['error']) {
            char_errors = true
          }
        })
        if (char_errors) {
          // eslint-disable-next-line vue/no-mutating-props
          this.error_data['char'] = 'Ошибка в характеристиках'
        }
      }
      delete this.error_data['images']
      if (!this.product.images.length) {
        // eslint-disable-next-line vue/no-mutating-props
        this.error_data['images'] = 'У товара нет фотографий'
      }
      delete this.error_data['barcode']
      if (!this.offer_data['marketplace_data'] || !this.offer_data['marketplace_data']['barcode']) {
        // eslint-disable-next-line vue/no-mutating-props
        this.error_data['barcode'] = 'У товара нет штрихкода'
      }
    }
  },
  watch: {
    offer: {
      handler(newOffer) {
        setTimeout(() => {
          if (this.offer_data['marketplace_data']) {
            this.offer_data['marketplace_data']['characteristics'].forEach((char) => this.checkChar(char))
          }
          this.checkForErrors()
        }, 100)
        this.offer_data = {...newOffer};
      }
    },
    batchAction: {
      handler(action) {
        if (action === 'WbDescription') {
          this.generateDescription()
        }
        if (action === 'WbAttributes') {
          this.generateAttributes()
        }
      }
    }
  },
}
</script>
<style>
h5.category {
  cursor: pointer;
}

.category_results {
  margin-top: 20px;
  min-height: 200px;
}

.category_results p {
  cursor: pointer;
}

.table td:first-child {
  width: 250px;
  cursor: pointer;
}

.table td.selected span {
  border: 2px #00a7bc solid;
}

.table td span {
  padding: 2px;
}

.offer-input:read-only {
  background: #fff;
  padding: 2px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.offer-input:focus {
  border: 1px #eee solid;
  width: 100%;
  outline: none;
}

.offer-input.by_ai_char, .descr-by-ai {
  background-color: rgba(115, 103, 240, 0.3);
}
</style>