<template>
  <div>
    <h5 class="category mt-5" @click="openCategorySelect">
      Категория:
      <span v-if="offer_data['marketplace_category']">
        {{ offer_data['marketplace_category']['name'] }}
      </span>
      <span v-if="!offer_data['marketplace_category']">не выбрана</span>
    </h5>
    <template v-if="offer_data['marketplace_category']">
      <ButtonComponent class="btn btn-outline-secondary" @click="categoryRule()">
        Правило для категории
      </ButtonComponent>

      <ButtonComponent v-if="offer_data['marketplace_category']" class="btn btn-outline-secondary"
                       style="margin-left: 3px"
                       @click="attributeRule()">
        Правило для характеристик
      </ButtonComponent>
      <p v-if="offer_data['marketplace_data'] && !isExpanded" class="mt-10 mb-5"
         :class="descriptionByAi ? 'descr-by-ai' : ''">
        {{ displayText }}<br>
        <a href="#" v-if="!isExpanded" @click.prevent="toggleExpand">Показать</a>
      </p>
      <textarea v-if="isExpanded" class="form-control mt-5 mb-1" :class="descriptionByAi ? 'descr-by-ai' : ''"
                v-model="offer_data['marketplace_data']['description']" width="100%" rows="10"></textarea>
      <div v-if="isExpanded" class="mb-5">
        <a href="#" @click.prevent="toggleExpand" class="mb-2">Скрыть </a>
      </div>
      <ButtonComponent ref="descriptionButton" class="btn btn-outline-secondary" @click="generateDescription">AI
        Описание
      </ButtonComponent>
      <ButtonComponent ref="attributesButton" class="btn btn-outline-secondary" style="margin-left: 5px;"
                       @click="generateAttributes">AI
        Характеристики
      </ButtonComponent>
      <table class="table mt-5" v-if="offer_data['marketplace_data']">
        <tbody>
        <tr>
          <td><b>Наименование</b></td>
          <td>
            <input
                class="offer-input" type="text"
                v-model="offer_data['marketplace_data']['name']"
                maxlength="500"
                @focus="isEditingIndex = 'title'"
                @blur="isEditingIndex = null"
                :readonly="isEditingIndex !== 'title'"
            >
          </td>
        </tr>
        <tr>
          <td><b>Штрихкод</b></td>
          <td>
            <input
                class="offer-input" type="text"
                v-model="offer_data['marketplace_data']['barcode']"
                maxlength="200"
                @focus="isEditingIndex = 'barcode'"
                @blur="isEditingIndex = null"
                :readonly="isEditingIndex !== 'barcode'"
            >
          </td>
        </tr>
        <tr>
          <td><b>Цена</b></td>
          <td>
            <input
                class="offer-input" type="text"
                v-model="offer_data['marketplace_data']['price']"
                maxlength="200"
                @focus="isEditingIndex = 'price'"
                @blur="isEditingIndex = null"
                :readonly="isEditingIndex !== 'price'"
            >
          </td>
        </tr>
        <tr>
          <td><b>Габариты (мм)</b></td>
          <td>{{ offer_data['marketplace_data']['width'] }} *
            {{ offer_data['marketplace_data']['height'] }} *
            {{ offer_data['marketplace_data']['depth'] }}
          </td>
        </tr>
        <tr v-for="(char, index) in offer_data['marketplace_data']['attributes']" :key="index">
          <td :class="{ selected: selectedAttribute === char['id'] }"
              @click="selectAttribute(char['id'])">
            <b v-if="char['required']">{{ char['name'] }} *</b>
            <span v-if="!char['required']">{{ char['name'] }}</span>
          </td>
          <td>
            {{ char['error'] }}
            <input
                v-if="char['dictionary_id'] <= 0"
                class="offer-input" type="text"
                v-model="formattedSingleValues[index]"
                @focus="isEditingIndex = index"
                @blur="isEditingIndex = null; checkSingleChar(char, index)"
                :readonly="isEditingIndex !== index"
                :class="{'by_ai_char': by_ai_chars.includes(char['id']) }"
            >
            <MultiSelect
                v-if="char['dictionary_id'] > 0"
                :show-toggle-all="false"
                filter
                v-model="formattedDictionary[index]"
                @focus="getDictionaryItems(char['id'])"
                @change="checkDictionary(char, index)"
                :options="dictionary_items[char['id']]"
                style="width: 100%"
                :class="{'by_ai_char': by_ai_chars.includes(char['id']) }"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
  <BaseModal modal-title="Выберите категорию Озон" ref="CategorySelect">
    <LoadingBlock :loading="searchLoading">
      <input type="text" class="form-control border-1 py-2" placeholder="Введите название"
             v-model="category_search" @change="searchCategory">
      <div class="category_results">
        <p v-for="(result, index) in category_results" :key="index" @click="setCategory(index)">
          {{ result.value['category_name'] }} - {{ result.value['type_name'] }}
        </p>
      </div>
    </LoadingBlock>
  </BaseModal>
  <BaseModal ref="ruleModal" modal-title="Новое правило">
    <RuleForm ref="ruleForm" @success="ruleSuccess()">

    </RuleForm>
  </BaseModal>
</template>


<script>

import BaseModal from "@/components/Modal.vue";
import {getInfo} from "@/api/marketplace";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import ButtonComponent from "@/components/tools/Button.vue";
import RuleForm from "@/components/forms/Rule.vue";
import {user} from "@/data/user";
import {description, ozon_attributes} from "@/api/gpt";
import {MultiSelect} from "primevue";

export default {
  name: "OzonOffer",
  components: {RuleForm, ButtonComponent, LoadingBlock, BaseModal, MultiSelect},
  data() {
    return {
      offer_data: {},
      category_search: '',
      category_results: [],
      searchLoading: false,
      selectedAttribute: null,
      isExpanded: false,
      isEditingIndex: null,
      descriptionByAi: false,
      by_ai_chars: [],
      dictionary_items: {},
      dictionary_loaded: []
    }
  },
  computed: {
    aa() {
      return ['ff']
    },
    displayText() {
      const description = this.offer_data['marketplace_data']['description'];
      if (!this.isExpanded) {
        return description.length > 100 ? description.substring(0, 100) + '...' : description;
      }
      return description;
    },
    formattedSingleValues() {
      const formated_values = []
      this.offer_data['marketplace_data'].attributes.forEach((attr) => {
        if (attr['dictionary_id'] > 0) {
          formated_values.push('')
          return
        }
        if (!attr.values || !attr.values.length) {
          formated_values.push('')
          return
        }
        formated_values.push(attr.values[0]['value'])

      })
      return formated_values;
    },
    formattedDictionary() {
      const formated_values = []
      this.offer_data['marketplace_data'].attributes.forEach((attr) => {
        if (attr['dictionary_id'] <= 0) {
          formated_values.push('')
          return
        }
        if (!attr.values) {
          formated_values.push('')
          return
        }
        const values = attr.values.map(item => item.value)
        if (!this.dictionary_items[attr['id']] && values.length) {
          this.dictionary_items[attr['id']] = values
        }
        formated_values.push(values)
      })
      return formated_values;
    }
  },
  props: {
    offer: {
      type: Object
    },
    error_data: {
      type: Object,
      mutable: true
    },
    product: {
      type: Object
    },
    productAttribute: {
      type: String
    },
    batchAction: {
      type: String
    }
  },
  emits: ['new-rule', 'offer-change'],
  methods: {
    async getDictionaryItems(char_id) {
      if (char_id in this.dictionary_loaded) {
        return
      }
      const key = this.offer_data['marketplace_category']['category_id'] + '-' + this.offer_data['marketplace_category']['type_id'] + '-' + char_id
      const data = {
        marketplace: 'ozon',
        info_type: 'ozon_attributes_values',
        key: key
      }
      const result = await getInfo(data)
      this.dictionary_items[char_id] = result['data']['items'][0]['value']['values'].map(item => item.value)
      this.dictionary_loaded.push(char_id)
    },
    openCategorySelect() {
      this.$refs.CategorySelect.openModal()
    },
    async searchCategory() {
      this.searchLoading = true
      const data = {
        marketplace: 'ozon',
        search_data: this.category_search,
        info_type: 'ozon_types'
      }
      const result = await getInfo(data)
      this.category_results = result.data.items
      this.searchLoading = false
    },
    setCategory(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.offer_data.marketplace_category = {
        name: this.category_results[index]['value']['category_name'] + ' - ' + this.category_results[index]['value']['type_name'],
        category_id: this.category_results[index]['value']['description_category_id'],
        type_id: this.category_results[index]['value']['type_id'],
      }
      this.$refs.CategorySelect.closeModal()
      this.$emit('offer-change', this.offer_data)
    },
    async categoryRule() {
      const company_id = await user.getActiveCompany()
      const rule = {
        company_id: company_id,
        supplier_id: this.product.supplier_id,
        marketplace: 'ozon',
        rule_type: 'category',
        supplier_info: this.product.category,
        marketplace_info: this.offer_data.marketplace_category['category_id'].toString() + '-' + this.offer_data.marketplace_category['type_id'].toString(),
        actions: []
      }
      setTimeout(() => {
        this.$refs.ruleModal.openModal()
        this.$refs.ruleForm.updateItem(rule)
      }, 100)
    },
    ruleSuccess() {
      this.$refs.ruleModal.closeModal()
      this.$emit('new-rule')
    },
    selectAttribute(attributeName) {
      this.selectedAttribute = attributeName; // Update the selected attribute
    },
    async attributeRule() {
      if (!this.selectedAttribute) {
        alert('Выберите характеристику маркетплейса')
        return
      }
      if (!this.productAttribute) {
        alert('Выберите характеристику товара')
        return
      }
      const company_id = await user.getActiveCompany()
      const rule = {
        company_id: company_id,
        supplier_id: this.product.supplier_id,
        marketplace: 'ozon',
        rule_type: 'attribute',
        supplier_info: this.productAttribute,
        marketplace_info: this.selectedAttribute.toString(),
        actions: []
      }
      setTimeout(() => {
        this.$refs.ruleModal.openModal()
        this.$refs.ruleForm.updateItem(rule)
      }, 100)
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },

    async generateDescription() {
      this.$refs.descriptionButton.startLoading()
      try {
        const result = await description({
          product_id: this.product.id,
          text: this.product.description,
          attributes: this.product.attributes
        })
        if (result.data.text) {
          this.offer_data['marketplace_data']['description'] = result.data.text
          this.descriptionByAi = true
        }
      } catch (e) {
        alert('Произошла ошибка')
      }
      this.$refs.descriptionButton.endLoading()
    },

    async generateAttributes() {
      this.$refs.attributesButton.startLoading()
      if (this.offer_data['marketplace_data']['attributes'] === undefined) {
        alert('У оффера нет характеристик')
        return
      }
      const attributes = this.offer_data['marketplace_data']['attributes']
      const by_ai_chars = this.by_ai_chars
      console.log(attributes)
      const charsWithoutValues = attributes.filter(
          (char) => !char.values.length
      )
      const data = {
        product_name: this.product.name,
        product_id: this.product.id,
        product_description: this.product.description,
        product_attributes: this.product.attributes,
        ozon_attributes: charsWithoutValues,
        ozon_category: this.offer_data['marketplace_category'],
        with_values: true
      }
      try {
        const result = await ozon_attributes(data)
        if (result.data['ozon_attributes']) {
          result.data['ozon_attributes'].forEach(function (char) {
            const id = char['id']
            const index = attributes.findIndex(
                (char) => char.id.toString() === id.toString()
            )
            const offer_char = attributes[index]
            if (offer_char !== undefined && char.values) {
              offer_char.values = char.values
              attributes[index] = offer_char
              by_ai_chars.push(char.id)
            }
          })
        }
      } catch (e) {
        alert('Произошла ошибка')
      }
      this.$refs.attributesButton.endLoading()
    },
    checkSingleChar(char, index) {
      char.values = [{value: this.formattedSingleValues[index]}]
      let error = ''

      char['error'] = error
    },
    checkDictionary(char, index) {
      char.values = this.formattedDictionary[index].map(item => {
        return {value: item}
      })
      let error = ''
      char['error'] = error
    },
    checkForErrors() {
      delete this.error_data['char']
      if (this.offer_data['marketplace_data']) {
        let char_errors = false
        this.offer_data['marketplace_data']['attributes'].forEach((char) => {
          if (char['error']) {
            char_errors = true
          }
          if (char['required'] && !char['values'].length) {
            char_errors = true
          }
        })
        if (char_errors) {
          // eslint-disable-next-line vue/no-mutating-props
          this.error_data['char'] = 'Ошибка в характеристиках'
        }
      }
      delete this.error_data['images']
      if (!this.product.images.length) {
        // eslint-disable-next-line vue/no-mutating-props
        this.error_data['images'] = 'У товара нет фотографий'
      }
      delete this.error_data['barcode']
      if (!this.offer_data['marketplace_data'] || !this.offer_data['marketplace_data']['barcode']) {
        // eslint-disable-next-line vue/no-mutating-props
        this.error_data['barcode'] = 'У товара нет штрихкода'
      }
      delete this.error_data['price']
      if (!this.offer_data['marketplace_data'] || !this.offer_data['marketplace_data']['price']) {
        // eslint-disable-next-line vue/no-mutating-props
        this.error_data['price'] = 'У товара нет цены'
      }
    }
  },
  watch: {
    offer: {
      handler(newOffer) {
        this.offer_data = {...newOffer};
        setTimeout(() => {
          this.checkForErrors()
        }, 1000);
      }
    },
    batchAction: {
      handler(action) {
        if (action === 'WbDescription') {
          this.generateDescription()
        }
        if (action === 'OzonAttributes') {
          this.generateAttributes()
        }
      }
    }
  },
}
</script>
<style>
h5.category {
  cursor: pointer;
}

.category_results {
  margin-top: 20px;
  min-height: 200px;
}

.category_results p {
  cursor: pointer;
}

.table td:first-child {
  width: 250px;
  cursor: pointer;
}

.table td.selected span {
  border: 2px #00a7bc solid;
}

.table td span {
  padding: 2px;
}

.offer-input:read-only {
  background: #fff;
  padding: 2px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.offer-input:focus {
  border: 1px #eee solid;
  width: 100%;
  outline: none;
}

.offer-input.by_ai_char, .descr-by-ai, .p-multiselect.by_ai_char {
  background-color: rgba(115, 103, 240, 0.3);
}
</style>