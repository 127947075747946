<template>
  <BaseLayout>
    <template v-slot:top_buttons>
      <div class="demo-inline-spacing">
        <button type="button" class="btn btn-primary" @click="create">
          Новое правило
        </button>
      </div>
    </template>

    <BaseModal ref="itemModal" :modal-title="modalTitle">
      <RuleForm ref="itemForm" @success="success">

      </RuleForm>
    </BaseModal>

    <div class="card">
      <div class="card-body">
        <div v-if="!items.length && !loading" class="mt-7">
          Правила не найдены
        </div>
      </div>
      <LoadingBlock :loading="loading">
        <div class="table-responsive text-nowrap">
          <table class="table">
            <tbody class="">
            <tr v-for="(item, index) in items" :key="index">
              <td><a href="#" @click.prevent="edit(item)">{{ item.supplier_info }} - {{ item.marketplace_info }}</a></td>
              <td style="text-align: right">
                <button type="button" class="btn btn-icon btn-label-primary waves-effect d-inline-block m-1"
                        @click="edit(item)">
                  <span class="ti ti-edit ti-md"></span>
                </button>
                <button type="button" class="btn btn-icon btn-label-secondary waves-effect d-inline-block"
                        @click="remove(item.id)">
                  <span class="ti ti-trash ti-md"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
          </BasePagination>
        </div>
      </LoadingBlock>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import BaseModal from "@/components/Modal.vue";
import BasePagination from "@/components/list/Pagination.vue"
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {getList, removeItem} from "@/api/rule";
import RuleForm from "@/components/forms/Rule.vue";

export default {
  name: 'RulesView',
  components: {RuleForm, LoadingBlock, BaseModal, BaseLayout, BasePagination},
  data() {
    return {
      items: [],
      loading: true,
      modalTitle: 'Новое правило',
      paginationInfo: {},
      searchData: {
        name: '',
        brand: ''
      }
    }
  },
  methods: {
    async getItems() {
      this.loading = true
      const result = await getList(user.active_company, this.$route.query)
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
      this.items = result.data.items
      this.loading = false
    },
    async search() {
      const query = {...this.$route.query}
      delete query.page
      Object.entries(this.searchData).forEach(([key, value]) => {
        if (value) {
          query[key] = value
        } else {
          delete query[key]
        }
      });
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...query
        }
      })

    },
    setSearch() {
      if (this.$route.query.name) {
        this.searchData.name = this.$route.query.name
      } else {
        this.searchData.name = ''
      }
      if (this.$route.query.brand) {
        this.searchData.brand = this.$route.query.brand
      } else {
        this.searchData.brand = ''
      }
    },
    async create() {
      this.modalTitle = 'Новое правило'
      setTimeout(() => {
        this.$refs.itemModal.openModal()
        this.$refs.itemForm.updateItem({})
      }, 100)
    },
    async edit(item) {
      this.modalTitle = 'Рекдактировать'
      setTimeout(() => {
        this.$refs.itemModal.openModal()
        this.$refs.itemForm.updateItem(item)
      }, 100)
    },
    async remove(product_id) {
      if (confirm('Вы уверены, что хотите удалить правило?')) {
        await removeItem(product_id)
        await this.getItems()
      }
    },
    async changePage(page) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page
        }
      });
    },
    setPageInfo() {
      PageInfo.title = 'Правила'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    async success(){
      this.$refs.itemModal.closeModal()
      await this.getItems()
    }
  },
  async mounted() {
    this.setPageInfo()
    this.setSearch()
    await user.getUserBase()
    await this.getItems()
  },
  watch: {
    '$route'() {
      this.setSearch()
      this.setPageInfo()
      this.getItems()
    }
  }
}
</script>