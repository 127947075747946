import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getList(company_id, query = '') {
    query = new URLSearchParams(query).toString()
    return await axios.get(
        `${API_URL}/v1/rule/list?company_id=${company_id}&${query}`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function removeItem(id) {
    return await axios.delete(
        `${API_URL}/v1/rule/${id}/`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function updateItem(id, data) {
    return await axios.post(
        `${API_URL}/v1/rule/${id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}

export async function createItem(data) {
    return await axios.post(
        `${API_URL}/v1/rule/create`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}
