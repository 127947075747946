import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";

export async function generateSingle(data) {
    return await axios.post(
        `${API_URL}/v1/infographics/generate/single?template_id=${data.template_id}&product_id=${data.product_id}&secondary_count=${data.secondary_count}`,
        {},
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}
