import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getProducts(company_id, query = '', active_store='') {
    const query_dict = new URLSearchParams(query)
    if (query_dict.has('has_not_sent_offer_in_store')) {
        query_dict.set('has_not_sent_offer_in_store', active_store);
    }
    if (query_dict.has('wb_subject_id')) {
        query_dict.set('wb_store_id', active_store);
    }
    if (!query_dict.has('size')) {
        query_dict.set('size', '20');
    }
    const query_ = query_dict.toString()
    return await axios.get(
        `${API_URL}/v1/product/list?company=${company_id}&${query_}`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function deleteProduct(product_id) {
    return await axios.delete(
        `${API_URL}/v1/product/${product_id}/delete`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

export async function updateProduct(product_id, data) {
    return await axios.put(
        `${API_URL}/v1/product/${product_id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}

export async function newProduct(data) {
    return await axios.post(
        `${API_URL}/v1/product/create`,
        data,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}

export async function uploadCosts(company_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(
        `${API_URL}/v1/product/costs/update-by-excel?company_id=${company_id}`,
        formData,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`, 'Content-Type': 'multipart/form-data'}},
    )
}

export async function uploadMinPrices(company_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(
        `${API_URL}/v1/product/min-price/update-by-excel?company_id=${company_id}`,
        formData,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`, 'Content-Type': 'multipart/form-data'}},
    )
}

export async function updateCosts(company_id, costs) {
    return await axios.post(
        `${API_URL}/v1/product/costs/update-by-code?company_id=${company_id}`,
        costs,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}

export async function updateMinPrices(company_id, minPrices) {
    return await axios.post(
        `${API_URL}/v1/product/min-price/update-by-code?company_id=${company_id}`,
        minPrices,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}},
    )
}